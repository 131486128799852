import { INeuroArchiveTemplate, INeuroTemplatedClinicalArchiveDocument } from 'neuro-data-structures';
import { partialDateToValue } from 'neuro-utils';
import { uniq } from 'ramda';
import { fetchWithOptions } from '../../../utility/fetch';
import { parseJWTFromCookie } from '../../../utility/jwtAuthTools';
import { makeLog } from '../../../utility/logger';
import { IArchiveObject, IClinicalTextObject } from './helpers';

const apiVersion = 'v3';
const archiveCreateMainAuthorHeaderName = 'author-user-id';
const secondaryAuthorRoleCode = 'KIR';

// Get list of templates
export const getTemplateList = async (
  org: string,
  module: string,
  docType: string,
  setError?: (e: string) => void,
): Promise<INeuroArchiveTemplate[] | Error> => {
  return await fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/templates/${org}/${module.toUpperCase()}/${docType}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else throw { message: 'Could not fetch template list', stack: res };
    })
    .catch((error: Error) => {
      makeLog('Error', error);
      setError && setError('template');
      return error;
    });
};

/** Create a new draft (unsent) archive text document. */
export const createDraftArchiveDocument = async (
  orgId: string,
  diseaseModule: string,
  archiveType: string,
  authorUserId: string,
  secondaryAuthorUserId: string | null,
  templateId: string,
  startDate: PartialDate,
  setError?: (e: string) => void,
) => {
  const startTimeStamp = partialDateToValue(startDate);

  const dataStartTimestamp = `?dataStartTimestamp=${startTimeStamp}`;

  try {
    const responseDraftInit = await fetchWithOptions(
      `/api/clinicalArchive/${apiVersion}/archive/${orgId}/${templateId}${dataStartTimestamp}`,
      {
        neurojwt: parseJWTFromCookie(),
        [archiveCreateMainAuthorHeaderName]: authorUserId,
      },
      { method: 'GET' },
    );
    if (responseDraftInit.status !== 200) {
      throw new Error(`Error creating archive doc: Upstream responded with status ${responseDraftInit.status}`);
    }
    const createdClinicalDocument: INeuroTemplatedClinicalArchiveDocument = await responseDraftInit.json();

    if (secondaryAuthorUserId) {
      const responsePatchSecondaryAuthor = await fetchWithOptions(
        `/api/clinicalArchive/${apiVersion}/authors/${orgId}/${diseaseModule}/${archiveType}/${createdClinicalDocument.id}`,
        {
          neurojwt: parseJWTFromCookie(),
        },
        {
          method: 'PATCH',
          body: JSON.stringify([
            {
              userId: secondaryAuthorUserId,
              authorRole: secondaryAuthorRoleCode,
              timestamp: Date.now(),
            },
          ]),
        },
      );
      if (responsePatchSecondaryAuthor.status !== 200) {
        throw new Error(
          `Error patching secondary author: Upstream responded with status ${responsePatchSecondaryAuthor.status}`,
        );
      }
    }

    return createdClinicalDocument;
  } catch (createDraftArchiveDocumentErr: any) {
    makeLog('Error', createDraftArchiveDocumentErr);
    if (setError) setError('createNew');
    return undefined;
  }
};

const sortAndFilterArchives = (data: INeuroTemplatedClinicalArchiveDocument[]): IArchiveObject[] => {
  if (data.length === 0) return [];

  const removedFiltered = data.filter((d) => !d.isRemoved);

  // Docs sorted by revisions (desc). Map to create a new array and sever any links
  const dataRevs = removedFiltered.map((d) => d).sort((a, b) => b.archiveRevision - a.archiveRevision);
  // Docs sorted by creation time.
  const dataTimes = removedFiltered.sort((a, b) => a.creationTimestamp - b.creationTimestamp);

  const ids = uniq(removedFiltered.map((d) => ({ id: d.archiveId, type: d.archiveType })));
  // Get first timestamps for ids
  const idsWithTimes = ids.map((i) => ({
    id: i.id,
    type: i.type,
    firstTimestamp: dataTimes.find((t) => t.archiveId === i.id && t.archiveType === i.type)?.['creationTimestamp'],
  }));
  // Get latest data for the ids with first timestamps
  const dataWithFirstTimeStamps = idsWithTimes.map((i) => ({
    ...(dataRevs.find(
      (d) => d.archiveId === i.id && d.archiveType === i.type,
    ) as INeuroTemplatedClinicalArchiveDocument),
    firstTimestamp: i.firstTimestamp,
  }));
  // Sort one last time so that they are descending based on firstTimestamp. If archiveDocumentId === null then that document should be first on the list.
  return dataWithFirstTimeStamps.sort((a, b) =>
    a.archiveId === null ? -1 : b.archiveId === null ? 1 : (b.firstTimestamp || 0) - (a.firstTimestamp || 0),
  );
};

// Get list of archive documents
export const getArchiveDocumentList = async (
  org: string,
  module: string,
  setError?: (e: string) => void,
): Promise<IArchiveObject[] | Error> => {
  return await fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/list/${org}/${module.toUpperCase()}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) return res.json();
      else throw { message: 'Could not fetch archive document list', stack: res };
    })
    .then((data: INeuroTemplatedClinicalArchiveDocument[]) => {
      const sortedList = sortAndFilterArchives(data);
      return sortedList;
    })
    .catch((error: Error) => {
      makeLog('Error', error);
      setError && setError('list');
      return error;
    });
};

// Send archive document
export const sendArchiveDocument = async (
  org: string,
  module: string,
  docType: string,
  clinicalDocument: IArchiveObject,
  startDate: PartialDate,
  reason: string | null,
  kantaDelayInput: number | null,
  setError?: (e: string) => void,
): Promise<'sent' | 'notSent' | false> => {
  const startTimeStamp = partialDateToValue(startDate);

  const textContentVersion = `?textContentVersion=${clinicalDocument.textContentVersion}`;
  const dataStartTimestamp = `&dataStartTimestamp=${startTimeStamp}`;
  const kantaDelayDays = typeof kantaDelayInput === 'number' ? `&kantaDelayDays=${kantaDelayInput}` : '';

  return await fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/archive/${org}/${module.toUpperCase()}/${docType}/${
      clinicalDocument.id
    }${textContentVersion}${dataStartTimestamp}${kantaDelayDays}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'POST', body: reason || 'noReasonForFirstRevision' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        const wasSent = res.headers.get('wassent');
        if (wasSent === 'true') return 'sent' as const;
        return 'notSent' as const;
      } else throw { message: 'Could not POST document', stack: res };
    })
    .catch((error: Error) => {
      makeLog('Error', error);
      setError && setError('send');
      return false;
    });
};

// Create new revision for an existing archive document
export const createNewArchiveRevision = async (
  org: string,
  module: string,
  docType: string,
  document: IArchiveObject,
  setError?: (e: string) => void,
): Promise<INeuroTemplatedClinicalArchiveDocument | null> => {
  return fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/amend/${org}/${module.toUpperCase()}/${docType}/${document.id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json().then((obj: INeuroTemplatedClinicalArchiveDocument) => {
          return obj;
        });
      } else {
        throw { message: 'Could not create new archive revision', stack: res };
      }
    })
    .then((data: INeuroTemplatedClinicalArchiveDocument) => {
      return data;
    })
    .catch((error: Error) => {
      makeLog('Error', error);
      setError && setError('newRevision');
      return null;
    });
};

/*
 * Update the 'custom' text content in the archive document
 */
export const updateArchiveDocumentText = async (
  org: string,
  module: string,
  docType: string,
  document: IArchiveObject,
  textChanges: IClinicalTextObject[],
  setError?: (e: string) => void,
): Promise<boolean> => {
  if (textChanges.length === 0 || textChanges.every((t) => t.text === undefined)) return false;

  return fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/archive/${org}/${module.toUpperCase()}/${docType}/${
      document.id
    }?textContentVersion=${document.textContentVersion}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'PATCH', body: JSON.stringify(textChanges) },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return true;
      } else {
        throw { message: 'Archive document text update not successful', stack: res };
      }
    })
    .catch((error) => {
      makeLog('Error', error);
      setError && setError('textUpdate');
      return false;
    });
};

export const deleteArchiveDocumentDraft = async (
  org: string,
  module: string,
  docType: string,
  document: IArchiveObject,
  setError?: (e: string) => void,
): Promise<boolean> => {
  return fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/archive/${org}/${module.toUpperCase()}/${docType}/${document.id}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return true;
      } else throw { message: 'Archive document draft deletion not successful', stack: res };
    })
    .catch((error) => {
      makeLog('Error', error);
      setError && setError('deletion');
      return false;
    });
};

export const deleteArchiveDocument = async (
  org: string,
  module: string,
  docType: string,
  document: IArchiveObject,
  reason: string,
  setError?: (e: string) => void,
): Promise<boolean> => {
  return fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/delete/${org}/${module.toUpperCase()}/${docType}/${document.archiveId}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'DELETE', body: reason },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return true;
      } else throw { message: 'Archive document deletion not successful', stack: res };
    })
    .catch((error) => {
      makeLog('Error', error);
      setError && setError('deletion');
      return false;
    });
};

export const refreshArchiveDocument = async (
  org: string,
  module: string,
  docType: string,
  document: IArchiveObject,
  startDate: PartialDate | number,
  endDate: PartialDate | number | null,
  upTextContentVersion?: boolean,
  setError?: (e: string) => void,
): Promise<INeuroTemplatedClinicalArchiveDocument | null> => {
  const startTimeStamp = Array.isArray(startDate) ? partialDateToValue(startDate) : startDate;
  const endTimeStamp = endDate && Array.isArray(endDate) ? partialDateToValue(endDate) : endDate;

  const textContentVersion = `?textContentVersion=${document.textContentVersion + (upTextContentVersion ? 1 : 0)}`;
  const dataStartTimestamp = `&dataStartTimestamp=${startTimeStamp}`;
  const dataEndTimestamp = endTimeStamp ? `&dataEndTimestamp=${endTimeStamp}` : '';

  return fetchWithOptions(
    `/api/clinicalArchive/${apiVersion}/refresh/${org}/${module.toUpperCase()}/${docType}/${
      document.id
    }${textContentVersion}${dataStartTimestamp}${dataEndTimestamp}`,
    { neurojwt: parseJWTFromCookie() },
    { method: 'GET' },
  )
    .then((res: Response) => {
      if (res.status === 200) {
        return res.json();
      } else throw { message: 'Archive document refresh not successful', stack: res };
    })
    .catch((error) => {
      makeLog('Error', error);
      setError && setError('refresh');
      return null;
    });
};
