import { Badge, Popover, TooltipProps } from '@mui/material';
import { includes } from 'ramda';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/system';

import AlertsSnack, { RenderAlerts } from '../../Alerts';
import { Container, Item } from '../../Grid';

import colors from '../../../config/theme/colors';
import { IContraIndicationsInquiry, contraIndicationsInquiry as ninmtContraIndicationsInquiry } from 'neuro-schemas';
import { formatPartialDate, nowPartialDate, partialDateFromDate, sortPartialDate } from 'neuro-utils';
import browserLanguage from 'Utility/browserLanguage';
import { omitControlProps } from 'Utility/documentHandling';
import { actions } from 'Store/documents/actions';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { useAppDispatch, useAppSelector } from 'Store/index';
import Icon from 'Components/_NewElements/Icon';
import ToolTip from 'Components/ToolTip';

const PopoverArea = styled('div')({
  padding: '2rem',
  minHeight: '0rem',
  maxHeight: '60rem',
  minWidth: '50rem',
});

const PopoverHeader = styled('div')({
  fontSize: '2.2rem',
  marginBottom: '2rem',
});

const NoAlertsText = styled('div')({
  fontSize: '2rem',
  color: colors.gray,
});

const badgeSX = (alternate?: boolean) => ({
  '& .MuiBadge-badge': {
    xs: {
      right: -2,
      top: 3,
      border: alternate ? '1px solid ' + colors.appBlue.light : 'none',
    },
    md: {
      right: 2,
      top: -6,
      border: alternate ? '1px solid ' + colors.appBlue.light : 'none',
    },
  },
});

const highestSeverity = (alerts: IAlert[]): 'error' | 'primary' =>
  includes(
    'error',
    alerts.map((a) => a.severity),
  )
    ? 'error'
    : 'primary';

const getContraIndicationsNotification = (
  alert: IAlert,
  contraIndicationsInquiry: IContraIndicationsInquiry & IControlProps,
  uiLanguage: Locale,
  loading: boolean,
  handleClick: () => void,
  fm: (id: string, values?: any) => string,
) => {
  const notifTitle =
    contraIndicationsInquiry.medicalOperations === 'no'
      ? fm('alerts.ninmt.contraIndicationsInquiryNo', {
          strong: (chunks: string) => <span style={{ fontWeight: '600' }}>{chunks}</span>,
          date: formatPartialDate(partialDateFromDate(new Date(contraIndicationsInquiry._docCreateDate))),
        })
      : fm('alerts.ninmt.contraIndicationsInquiryYes', {
          strong: (chunks: string) => <span style={{ fontWeight: 'bold' }}>{chunks}</span>,
        });
  const rtmsAbsolutes: IContraIndicationsInquiry['rtmsContraIndications'] = [
    'foreignObjectsInBrain',
    'otherMagneticForeignObjectsInHead',
    'pacemaker',
    'metallicCardiacValveProsthesis',
    'innerEarProsthesis',
    'stimulatorAtNeck',
  ];
  const rtmsRelatives: IContraIndicationsInquiry['rtmsContraIndications'] = [
    'intracranialForeignObjects',
    'insulinPump',
    'infusionPortsAtUpperBody',
    'nerveStimulators',
    'epilepsy',
    'diseasesWithLowerSeizureThreshold',
    'pregnancy',
    'nonRemovableTattoosOrPiercingsInHead',
  ];
  const contraIndicationsDescriptionItems: {
    rtmsAbsolute?: string[];
    rtmsRelative?: string[];
    tdcsAbsolute?: string[];
    details?: string;
  } =
    contraIndicationsInquiry.rtmsContraIndications &&
    contraIndicationsInquiry.rtmsContraIndications.length > 0 &&
    !contraIndicationsInquiry.rtmsContraIndications.includes('noneOfThese')
      ? {
          rtmsAbsolute: contraIndicationsInquiry.rtmsContraIndications?.filter((ci) => rtmsAbsolutes.includes(ci)),
          rtmsRelative: contraIndicationsInquiry.rtmsContraIndications?.filter((ci) => rtmsRelatives.includes(ci)),
        }
      : {};

  if (
    contraIndicationsInquiry.tdcsContraIndications &&
    contraIndicationsInquiry.tdcsContraIndications.length > 0 &&
    !contraIndicationsInquiry.tdcsContraIndications.includes('noneOfThese')
  ) {
    contraIndicationsDescriptionItems['tdcsAbsolute'] = contraIndicationsInquiry.tdcsContraIndications;
  }
  if (
    contraIndicationsInquiry.rtmsContraIndications?.includes('noneOfThese') ||
    contraIndicationsInquiry.tdcsContraIndications?.includes('noneOfThese')
  ) {
    contraIndicationsDescriptionItems['details'] = contraIndicationsInquiry.healthChangeDetails;
  }

  const locales = ninmtContraIndicationsInquiry.localizations[uiLanguage];

  const contraIndicationsDesc = (): JSX.Element => (
    <Container direction={'column'} style={{ marginTop: '1rem' }}>
      {contraIndicationsDescriptionItems?.rtmsAbsolute && contraIndicationsDescriptionItems.rtmsAbsolute.length > 0 && (
        <>
          <Item style={{ fontWeight: '600' }}>
            {fm('diagnosis.ninmt.contraIndicationsToTreatment.tmsAbsoluteContraIndications')}
          </Item>
          {contraIndicationsDescriptionItems.rtmsAbsolute.map((a) => {
            return <Item key={a}>{`- ${locales[`opts.${a}`]}`}</Item>;
          })}
        </>
      )}
      {contraIndicationsDescriptionItems?.rtmsRelative && contraIndicationsDescriptionItems.rtmsRelative.length > 0 && (
        <>
          <Item style={{ fontWeight: '600' }}>
            {fm('diagnosis.ninmt.contraIndicationsToTreatment.tmsRelativeContraIndications')}
          </Item>
          {contraIndicationsDescriptionItems.rtmsRelative.map((a) => {
            return <Item key={a}>{`- ${locales[`opts.${a}`]}`}</Item>;
          })}
        </>
      )}
      {contraIndicationsDescriptionItems?.tdcsAbsolute && contraIndicationsDescriptionItems.tdcsAbsolute.length > 0 && (
        <>
          <Item style={{ fontWeight: '600' }}>
            {fm('diagnosis.ninmt.contraIndicationsToTreatment.dcsAbsoluteContraIndications')}
          </Item>
          {contraIndicationsDescriptionItems.tdcsAbsolute.map((a) => {
            return <Item key={a}>{`- ${locales[`opts.${a}`]}`}</Item>;
          })}
        </>
      )}
      {contraIndicationsDescriptionItems?.details && (
        <>
          <Item style={{ fontWeight: '600' }}>{fm('alerts.ninmt.healthChangeDetails')}</Item>
          <Item>{contraIndicationsDescriptionItems.details}</Item>
        </>
      )}
      {contraIndicationsInquiry.medicalOperations === 'no' && (
        <div style={{ alignSelf: 'flex-end' }}>
          <ActionButtonRounded
            text="alerts.ninmt.contraIndicationButton"
            onClick={handleClick}
            loading={loading}
            colorScheme={'warning'}
            uppercase={false}
            fontSize={16}
            height={4}
            width={32}
          />
        </div>
      )}
    </Container>
  );
  return {
    textID: 'alerts.ninmt.contraIndicationsInquiry',
    severity: alert.severity,
    customText: notifTitle,
    desc: contraIndicationsDesc(),
    id: alert.id,
  };
};

export const AlertsPopUps = ({ alerts }: { alerts: IAlert[] }): JSX.Element => {
  const uiLanguage = useAppSelector((state: IState) => state.settings.userSettings.uiLanguage) || browserLanguage;
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const fm = (id: string, values?: any) => formatMessage({ id }, values);

  const allDocs = useAppSelector((s: IState) => s.documents.sortedAndMergedDocuments);
  const myAppDocs = useAppSelector((s: IState) => s.myapp.sortedAndMergedDocuments);

  const extraSnacks = useAppSelector((s) => s.session.snacks?.extraSnacks) || [];
  const hiddenSnacks = useAppSelector((s) => s.session.snacks?.hiddenSnacks) || [];
  const filteredSnacks = [...alerts.filter((a) => !hiddenSnacks.includes(a.id)), ...extraSnacks];

  return (
    <AlertsSnack
      alerts={filteredSnacks
        .filter((a) => a.popup)
        .map((b) => {
          if (b.content === 'alerts.ninmt.contraIndicationsInquiry') {
            const latestContraIndInquiry: IContraIndicationsInquiry & IControlProps =
              (myAppDocs ?? []).filter((d) => d._type === 'contraIndicationsInquiry')[0] || {};

            const contraIndicDocs = allDocs?.filter((d) => d._type === 'contraIndicationsToTreatment');
            const latestContraIndicDoc = omitControlProps(
              (contraIndicDocs ?? []).sort((a, b) => sortPartialDate(b.date, a.date))[0],
            );

            const handleClick = () => {
              setButtonLoading(true);

              actions.putDocument('contraIndicationsToTreatment', {
                ...latestContraIndicDoc,
                date: nowPartialDate(),
              })(dispatch);
              setButtonLoading(false);
            };

            return getContraIndicationsNotification(
              b,
              latestContraIndInquiry,
              uiLanguage,
              buttonLoading,
              handleClick,
              fm,
            );
          }
          if (typeof b.content !== 'string') {
            return { severity: b.severity, customText: b.content, textID: 'customtextAlert', id: b.id };
          } else return { textID: b.content, severity: b.severity, id: b.id };
        })}
    />
  );
};

export const AlertsPopover = ({
  iconSize,
  alerts,
  alternateStyle,
  tooltipPlacement,
}: {
  iconSize?: number;
  alerts: IAlert[];
  alternateStyle?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
}): JSX.Element => {
  const [el, setEl] = React.useState<SVGSVGElement | HTMLElement | null>(null);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const { formatMessage } = useIntl();
  const fm = (id: string, values?: any) => formatMessage({ id }, values);
  const dispatch = useAppDispatch();

  const handleOpen = (event: React.MouseEvent<SVGSVGElement | HTMLElement>): void =>
    !el ? setEl(event.currentTarget) : setEl(null);

  const allDocs = useAppSelector((s: IState) => s.documents.sortedAndMergedDocuments) || [];
  const myAppDocs = useAppSelector((s: IState) => s.myapp.sortedAndMergedDocuments);
  const uiLanguage = useAppSelector((state: IState) => state.settings.userSettings.uiLanguage) || browserLanguage;

  return (
    <React.Fragment>
      <Popover
        open={!!el}
        onClose={handleOpen}
        anchorEl={el}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
        disableScrollLock
      >
        <PopoverArea>
          <PopoverHeader>{fm('alerts.notifications')}</PopoverHeader>
          {alerts && alerts.length > 0 ? (
            <RenderAlerts
              alerts={alerts.map((b) => {
                if (b.content === 'alerts.ninmt.contraIndicationsInquiry') {
                  const latestContraIndInquiry: IContraIndicationsInquiry & IControlProps =
                    (myAppDocs ?? []).filter((d) => d._type === 'contraIndicationsInquiry')[0] || {};

                  const contraIndicDocs = allDocs?.filter((d) => d._type === 'contraIndicationsToTreatment');
                  const latestContraIndicDoc = omitControlProps(
                    (contraIndicDocs ?? []).sort((a, b) => sortPartialDate(b.date, a.date))[0],
                  );

                  const handleClick = () => {
                    setButtonLoading(true);

                    actions.putDocument('contraIndicationsToTreatment', {
                      ...latestContraIndicDoc,
                      date: nowPartialDate(),
                    })(dispatch);
                    setButtonLoading(false);
                  };

                  return getContraIndicationsNotification(
                    b,
                    latestContraIndInquiry,
                    uiLanguage,
                    buttonLoading,
                    handleClick,
                    fm,
                  );
                }
                if (typeof b.content !== 'string') {
                  return { severity: b.severity, customText: b.content, textID: 'customtextAlert', id: b.id };
                } else return { textID: b.content, severity: b.severity, id: b.id };
              })}
              topMargin={false}
              closeable={false}
              elevation={0}
            />
          ) : (
            <Container alignItems="center" justifyContent="center" style={{ height: '5rem', marginBottom: '1rem' }}>
              <Item>
                <NoAlertsText>{fm('alerts.noNotifications')}</NoAlertsText>
              </Item>
            </Container>
          )}
        </PopoverArea>
      </Popover>
      <ToolTip
        title={fm('alerts.notifications')}
        placement={tooltipPlacement}
        hover
        content={
          <Badge
            badgeContent={alerts && alerts.length > 0 ? alerts.length : 0}
            color={highestSeverity(alerts) || 'primary'}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={badgeSX(alternateStyle)}
          >
            <div onClick={handleOpen}>
              <Icon size={iconSize} icon="bell" color="appBlueLight" button />
            </div>
          </Badge>
        }
      />
    </React.Fragment>
  );
};
