import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { mergeRight } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ActionButton from '../../ActionButton';
import { Container, Item } from '../../Grid';
import FormRow from '../../FormRow';
import InputHandler from '../../InputHandler';

import { fetchWithOptions } from '../../../utility/fetch';
import { exists } from 'neuro-utils';

import { dialogActions, dialogCancel, dialogContent, dialogTitle } from '../../../config/theme/componentTheme';
import { parseJWTFromCookie } from '../../../utility/jwtAuthTools';
import colors from '../../../config/theme/colors';
import { makeLog } from '../../../utility/logger';

import { useAppSelector as useSelector } from 'Store/index';

const sendData = (
  formData: IContactData,
  setRequestSent: (s?: 'suc' | 'err') => void,
  setFormData: (f: IContactData) => void,
  orgId: string,
  patientId?: string,
): void => {
  const createCustomer = (): Promise<void | Response> =>
    fetchWithOptions(
      '/api/cs/v1/servicedeskapi/customer',
      {
        neurojwt: parseJWTFromCookie(),
        'Content-Type': 'application/json',
      },
      {
        method: 'post',
        body: JSON.stringify({
          displayName: formData.email,
          email: formData.email,
        }),
      },
    ).catch((err) => {
      setRequestSent('err');
      makeLog('Error', err, 'Create customer fetch');
    });

  const description = formData.relatesToCurrentPatient
    ? formData.description?.concat('\n\n\nOrganization: ' + orgId + '\nPatient: ' + patientId?.substring(0, 8))
    : formData.description + ('\n\n\nOrganization: ' + orgId);

  const createRequest = (): Promise<void | Response> =>
    fetchWithOptions(
      '/api/cs/v1/servicedeskapi/request',
      {
        neurojwt: parseJWTFromCookie(),
      },
      {
        method: 'post',
        body: JSON.stringify({
          // These should be appended in back-end based on the environment.
          // serviceDeskId: 1=Demo service desk, 2=SQ Service Desk
          requestFieldValues: {
            summary: formData.summary,
            description: description,
          },
          raiseOnBehalfOf: formData.email,
          // This is mapped in backend to match right service desk ids
          // Demo: map to id '1'
          // SQ: Map 'incident' => '14', 'improvement' => '16', 'other' => '12'
          requestTypeId: formData.requestType,
        }),
      },
    )
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setRequestSent('suc');
          setFormData({});
        } else {
          setRequestSent('err');
          makeLog('Error', {
            name: 'Error ' + res.status,
            message: 'Error creating request. (missing fields/bad formatting/etc)',
          });
        }
      })
      .catch((err) => {
        setRequestSent('err');
        makeLog('Error', err, 'Create request fetch');
      });

  createCustomer().then(() => createRequest());
};

const ContactForm = ({ openDialog, setOpenDialog }: IOwnProps): JSX.Element => {
  const [formData, setFormData] = React.useState<IContactData>({});
  const [fieldsNotFilled, setFieldsNotFilled] = React.useState<boolean>(false);
  const [requestSent, setRequestSent] = React.useState<'suc' | 'err' | 'wait' | undefined>(undefined);
  //const [attachment, setAttachment] = React.useState<File | undefined>(undefined);

  const onChange = (values: TOnChangeValues): void => {
    const name = Object.keys(values)[0];
    const value = values[name];

    const saveValue = (value: object): void => setFormData(mergeRight(formData, value));

    if (name && (exists(value) || value === false)) {
      saveValue({ [name]: value });
    }
  };

  // const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
  //   setAttachment(e.target?.files?.[0]);
  // };

  const session = useSelector((s: { session: ISessionStore }) => s.session);

  const formValidateAndSend = (): void => {
    const requiredFields = ['requestType', 'email', 'summary', 'description'];
    if (requiredFields.some((f) => !formData[f])) {
      setFieldsNotFilled(true);
    } else {
      setFieldsNotFilled(false);
      setRequestSent('wait');

      if (session.data?.orgid) {
        if (!formData.relatesToCurrentPatient) sendData(formData, setRequestSent, setFormData, session.data?.orgid);
        else if (session.data?.patientid) {
          sendData(formData, setRequestSent, setFormData, session.data?.orgid, session.data?.patientid);
        } else {
          setRequestSent('err');
        }
      } else {
        setRequestSent('err');
      }
    }
  };

  const closeDialog = (): void => {
    // Clear dialog contents when closing
    setOpenDialog(false)();
    setFormData({});
    setFieldsNotFilled(false);
    setRequestSent(undefined);
    //setAttachment(undefined);
  };

  return (
    <Dialog open={openDialog} maxWidth="lg" fullWidth={true} PaperProps={{ square: true }}>
      <DialogTitle style={dialogTitle}>
        <FormattedMessage id="header.feedbackForm.header" />
      </DialogTitle>

      <DialogContent style={dialogContent}>
        <FormRow title="header.feedbackForm.requestType">
          <InputHandler
            type="Select"
            name="requestType"
            editing={true}
            formData={{ document: { requestType: formData.requestType }, onChange }}
            options={['incident', 'improvement', 'other']}
            optionFormatter={(n: React.ReactText): JSX.Element => (
              <FormattedMessage id={'header.feedbackForm.opts.' + n} />
            )}
            placeholder="header.feedbackForm.requestTypePlaceholder"
          />
        </FormRow>
        <FormRow
          title="header.feedbackForm.email"
          description={<FormattedMessage id="header.feedbackForm.emailDisclaimer" />}
        >
          <InputHandler
            type="TextField"
            name="email"
            editing={true}
            formData={{ document: { email: formData.email }, onChange }}
            placeholder={'header.feedbackForm.emailPlaceholder'}
          />
        </FormRow>
        <FormRow title="header.feedbackForm.summary">
          <InputHandler
            type="TextField"
            name="summary"
            editing={true}
            formData={{ document: { summary: formData.summary }, onChange }}
            placeholder={'header.feedbackForm.summaryPlaceholder'}
          />
        </FormRow>
        <FormRow title="header.feedbackForm.description">
          <InputHandler
            type="TextArea"
            name="description"
            editing={true}
            formData={{ document: { description: formData.description }, onChange }}
            placeholder={'header.feedbackForm.descriptionPlaceholder'}
            width={50}
          />
        </FormRow>
        <FormRow title="header.feedbackForm.relatesToCurrentPatient">
          <InputHandler
            type="CheckboxSingle"
            name="relatesToCurrentPatient"
            editing={true}
            formData={{ document: { relatesToCurrentPatient: formData.relatesToCurrentPatient }, onChange }}
            option="true"
            showLabel={false}
          />
        </FormRow>
        <div style={{ fontWeight: 600 }}>
          <FormRow title="header.feedbackForm.requiredFields">
            <></>
          </FormRow>
        </div>
        {/* <FormRow title="header.feedbackForm.attachment">
          <input type="file" onChange={onChangeFile} />
        </FormRow> */}
        <Container justifyContent="flex-end">
          <Item style={{ fontWeight: 600 }}>
            {fieldsNotFilled && (
              <div style={{ color: colors.primaryWarning }}>
                <FormattedMessage id="header.feedbackForm.fieldsNotFilled" />
              </div>
            )}
            {requestSent && (
              <div style={{ color: colors.primaryText }}>
                {requestSent === 'suc' ? (
                  <FormattedMessage id="header.feedbackForm.success" />
                ) : (
                  <FormattedMessage id="header.feedbackForm.error" />
                )}
              </div>
            )}
          </Item>
        </Container>
      </DialogContent>
      <DialogActions style={dialogActions}>
        {requestSent === 'suc' ? (
          <div style={dialogCancel} onClick={closeDialog}>
            <FormattedMessage id="header.feedbackForm.close" />
          </div>
        ) : (
          <>
            <div style={dialogCancel} onClick={closeDialog}>
              <FormattedMessage id="general.cancel" />
            </div>
            <ActionButton
              text="header.feedbackForm.send"
              onClick={formValidateAndSend}
              width={15}
              height={4}
              fontSize={16}
              loading={requestSent === 'wait'}
            />
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

interface IContactData {
  [key: string]: string | object | [] | undefined | boolean;
  requestType?: string; // request type - mandatory
  email?: string; // email - mandatory
  summary?: string; // mandatory
  description?: string; // mandatory
  attachments?: [];
  relatesToCurrentPatient?: boolean;
}

interface IOwnProps {
  openDialog: boolean;
  setOpenDialog: (open: boolean) => () => void;
}

export default ContactForm;
